import { Fragment, useState } from 'react';

import { ModalProceed, ModalConfirmation, message } from 'src/components/Misc';
import { useTerminateCustodianProfileMutation } from 'src/graphql/schema';
import { useCustodianProfile } from 'src/context/custodian';
import { getApiError, ClientError } from 'src/utils/errors';

const CustodianTerminateProfile = () => {
  const [isRequestSent, setIsRequestSent] = useState(false);

  const {
    custodianProfile,
    ui: { isTerminateModalOpen, hideTerminateModal },
  } = useCustodianProfile();
  const [terminateProfile, { loading }] = useTerminateCustodianProfileMutation();

  const onTerminate = async () => {
    try {
      const { data } = await terminateProfile({
        variables: {
          id: custodianProfile.id,
        },
      });

      if (data?.terminateCustodianProfile?.success) {
        hideTerminateModal();
        setIsRequestSent(true);
      } else {
        throw new ClientError(data?.terminateCustodianProfile?.errors?.[0]);
      }
    } catch (error: unknown) {
      getApiError(error, message.error);
    }
  };

  const onConfirmationClose = () => setIsRequestSent(false);

  return (
    <Fragment>
      <ModalProceed
        isOpen={isTerminateModalOpen}
        title="Are you sure you want to request termination of this profile?"
        text="All materials connected in this profile will be removed, and you will no longer have the full lifecycle protection of this Custodian."
        isLoading={loading}
        onCancel={hideTerminateModal}
        onProceed={onTerminate}
        proceedText="Request Termination"
        proceedButtonProps={{
          danger: true,
          ghost: true,
        }}
      />

      <ModalConfirmation
        isOpen={isRequestSent}
        title="Termination request sent"
        text="Please proceed by revoking Codekeeper's access to all the providers protected by this Custodian."
        onClose={onConfirmationClose}
      />
    </Fragment>
  );
};

export default CustodianTerminateProfile;
