import { Menu, Button, ConfigProvider, Skeleton } from 'antd';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import { useLocalStorage } from 'src/hooks';
import { Logo, IconLogo } from 'src/components/Svg/Images';

import type { MenuProps } from 'antd';

import {
  escrowMenuItems,
  saasMenuItems,
  otherMenuItems,
  backupMenuItems,
  custodianMenuItems,
} from './SidebarMenuItems';
import styles from './Sidebar.module.scss';

export type IMenuItem = Required<MenuProps>['items'][number];

type Props = {
  migrationsCount: number;
  withBilling: boolean;
  isLoading?: boolean;
};

const items = [...backupMenuItems, ...escrowMenuItems, ...custodianMenuItems, ...saasMenuItems];

const Sidebar: React.FunctionComponent<Props> = ({ migrationsCount, withBilling, isLoading = false }) => {
  const [collapsed, setCollapsed] = useLocalStorage('sidebarCollapsed', false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleCollapse = () => setCollapsed(!collapsed);

  const sidebarClasses = classNames('ck-sidebar', {
    [styles.collapsed]: collapsed,
  });

  const onSelect = ({ key }: { key: string }) => navigate(key);

  return (
    <div className={sidebarClasses}>
      <div className={styles['logo-wrapper']}>
        <Link to="/">
          {collapsed ? (
            <IconLogo className={`${styles.logo} ${styles['logo-icon']}`} />
          ) : (
            <Logo className={`${styles.logo} ${styles['logo-full']}`} />
          )}
        </Link>
      </div>
      {isLoading ? (
        <Skeleton
          className={styles.skeleton}
          active
          paragraph={{
            rows: 6,
          }}
        />
      ) : (
        <ConfigProvider
          theme={{
            components: {
              Menu: {
                iconSize: 16,
                collapsedIconSize: 18,
                itemSelectedBg: '#EFF7FF',
              },
            },
          }}
        >
          <Menu
            selectedKeys={[pathname]}
            mode="inline"
            theme="light"
            inlineCollapsed={collapsed}
            items={[
              ...items,
              ...otherMenuItems({
                migrationsCount,
                withBilling,
              }),
            ]}
            inlineIndent={16}
            onSelect={onSelect}
          />
        </ConfigProvider>
      )}

      <div className={styles['btn-collapse']}>
        <Button type="link" onClick={handleCollapse}>
          {collapsed ? <RightOutlined /> : <LeftOutlined />}
        </Button>
      </div>
    </div>
  );
};

export default Sidebar;
