import { useNavigate } from 'react-router-dom';

import { JourneyHeader } from 'src/components/Journey';
import { useCustodianProfile } from 'src/context/custodian';
import { custodianLabelStatusMap } from 'src/components/Custodian/model';

const CustodianModalHeaderContainer = () => {
  const { custodianProfile, isTerminationRequested } = useCustodianProfile();
  const navigate = useNavigate();
  const status = isTerminationRequested ? 'termination_requested' : custodianProfile.status;
  const statusType = custodianLabelStatusMap[status];

  const onBack = () => navigate(-1);

  return (
    <JourneyHeader
      statusLabel={statusType}
      breadcrumbItems={[
        {
          path: 'custodian-profiles',
          title: 'Custodian',
        },
        {
          title: custodianProfile.name,
        },
      ]}
      onBack={onBack}
    />
  );
};

export default CustodianModalHeaderContainer;
