import { message } from 'antd';

import {
  IOrganizationForm,
  ISignatoryForm,
  IAddContactForm,
  EditableDetailsByRole,
  IContactDetail,
} from 'src/components/Escrow/Contacts';
import {
  IEscrowPartyInput,
  EscrowPartyTypeEnum,
  ContactTypeEnum,
  useUpdateContactMutation,
  useRemoveContactMutation,
  useUpdateSignatureMutation,
  useUpdateBeneficiaryMutation,
  useUpdateDepositorMutation,
  Exact,
} from 'src/graphql/schema';
import { useEscrow } from 'src/context/escrow';
import styles from 'src/components/Escrow/Contacts/EscrowContacts.module.scss';
import { ClientError, getApiError } from 'src/utils/errors';
import { updateContactsCache, updateDepositorCache, updateBeneficiaryCache } from 'src/graphql/client/cache';

import EscrowAddNewContactContainer from './EscrowAddNewContactContainer';

type Props = {
  role: EscrowPartyTypeEnum;
};

const EscrowMainPartyEditableContainer: React.FunctionComponent<Props> = ({ role }) => {
  const { escrow } = useEscrow();
  const [updateSignature] = useUpdateSignatureMutation();
  const [updateContact] = useUpdateContactMutation();
  const [removeContact] = useRemoveContactMutation();
  const [updateDepositor] = useUpdateDepositorMutation({
    update: (_, { data }) => updateDepositorCache(escrow.id, data?.updateDepositor?.depositor),
  });
  const [updateBeneficiary] = useUpdateBeneficiaryMutation({
    update: (_, { data }) => updateBeneficiaryCache(escrow.id, data?.updateBeneficiary?.beneficiary),
  });

  const escrowId = escrow.id;
  const organization = escrow[role];
  const escrowPartyId = escrow[role]?.id || '';
  const signatory = escrow.signatures.find((signature) => signature.role === role);
  const contacts: IContactDetail[] =
    escrow[role]?.contacts.map(({ id, contactType, user }) => ({
      id: id || '',
      email: user?.email || '',
      name: user?.name || '',
      phone: user?.phone || '',
      role: contactType || '',
      isDynamic: true,
    })) || [];

  const onSubmitOrganization = async ({
    name,
    street,
    streetNumber,
    city,
    zip,
    state,
    country,
    website,
    registrationNumber,
  }: IOrganizationForm) => {
    const companyVariables: IEscrowPartyInput = {
      companyName: name,
      street,
      streetNumber,
      city,
      postalCode: zip,
      region: state,
      country,
      companyWebsite: website,
      companyRegistrationNumber: registrationNumber,
    };
    const updateByRole = role === EscrowPartyTypeEnum.Depositor ? updateDepositor : updateBeneficiary;
    const dataUpdateType = role === EscrowPartyTypeEnum.Depositor ? 'updateDepositor' : 'updateBeneficiary';

    try {
      const { data } = await updateByRole({
        variables: {
          [`${role}Id`]: escrowPartyId,
          [`${role}InputParams`]: companyVariables,
        } as Exact<{
          depositorId: string;
          depositorInputParams: IEscrowPartyInput;
        }> &
          Exact<{ beneficiaryId: string; beneficiaryInputParams: IEscrowPartyInput }>,
      });

      // @ts-expect-error dynamic key
      if (data?.[dataUpdateType]?.errors?.length) {
        // @ts-expect-error dynamic key
        throw Error(data?.[dataUpdateType]?.errors[0]);
      } else {
        message.success('Details updated!');
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        message.error(e.message || 'Something went wrong!');
        throw e;
      }
    }
  };
  const onSubmitSignatory = async ({ email, name }: ISignatoryForm) => {
    try {
      const { data } = await updateSignature({
        variables: {
          escrowId,
          signatureInput: {
            email,
            name,
            role,
          },
        },
      });

      if (data?.updateSignature?.errors?.length) {
        throw new ClientError(data?.updateSignature?.errors[0]);
      } else {
        message.success('Added signature!');
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        getApiError(e, message.error);
        throw e;
      }
    }
  };
  const onSubmitContact = async (id: string, { email, name, role, phone }: IAddContactForm) => {
    try {
      const { data } = await updateContact({
        variables: {
          contactId: id,
          contactInputParams: {
            name,
            email,
            phone,
            contactType: role as ContactTypeEnum,
          },
        },
      });

      if (data?.updateContact?.errors?.length) {
        throw Error(data?.updateContact?.errors[0]);
      } else {
        message.success('Contact updated!');
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        message.error(e.message || 'Something went wrong!');
        throw e;
      }
    }
  };
  const onRemoveContact = async (contactId: string) => {
    try {
      const { data } = await removeContact({
        variables: {
          contactId,
        },
      });

      if (data?.removeContact?.errors?.length) {
        throw Error(data?.removeContact?.errors[0]);
      } else {
        const filteredContacts = organization?.contacts.filter(({ id }) => id !== contactId);
        updateContactsCache(escrow.id, role, organization, filteredContacts);
        message.success('The contact was removed!');
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        message.error(e.message || 'Something went wrong!');
        throw e;
      }
    }
  };

  return (
    <div className={styles.container}>
      <EditableDetailsByRole
        isOwnOrganization
        role={role}
        organization={{
          name: organization?.companyName || '',
          address: {
            street: organization?.street || '',
            streetNumber: organization?.streetNumber || '',
            city: organization?.city || '',
            postalCode: organization?.postalCode || '',
            region: organization?.region || '',
            country: organization?.country || '',
          },
          registrationNumber: organization?.companyRegistrationNumber || '',
          website: organization?.companyWebsite || '',
        }}
        signatory={signatory}
        contacts={contacts}
        onSubmitSignatory={onSubmitSignatory}
        onSubmitContact={onSubmitContact}
        onSubmitOrganization={onSubmitOrganization}
        onRemoveContact={onRemoveContact}
      />

      <EscrowAddNewContactContainer role={role} />
    </div>
  );
};

export default EscrowMainPartyEditableContainer;
