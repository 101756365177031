import { Fragment, useRef, useCallback, useEffect } from 'react';
import { Button, message } from 'antd';

import { JourneyModalLayout } from 'src/components/Journey/ModalLayout';
import {
  EditBackupSettingsForm,
  BackupSettingInfoBlock,
  IEditBackupSettingsForm,
} from 'src/components/Backup/Settings';
import { TextBlock } from 'src/components/Misc';
import {
  useUpdateBackupMutation,
  BackupDocument,
  DisruptionNotificationGracePeriodEnum,
  DisruptionNotificationEnum,
} from 'src/graphql/schema';
import { useBackup } from 'src/context/backup';
import {
  versionsOptionsDict,
  disruptedNotificationsOptionsDict,
  gracePeriodOptionsDict,
} from 'src/components/Backup/backup.enum';
import { storageRegionDictionary } from 'src/components/Escrow/Notifications/escrowNotifications.enum';
import { ClientError, getApiError } from 'src/utils/errors';
import styles from 'src/components/Backup/Settings/Form/BackupSettingsForm.module.scss';

import type { FormInstance } from 'antd/lib/form';

const JourneyModalFooter = JourneyModalLayout.Footer;

const BackupSettingsContainer = () => {
  const formRef = useRef<FormInstance>(null);
  const [updateBackup, { loading, client }] = useUpdateBackupMutation();
  const { backup, ui, setEditSettingsMode } = useBackup();

  useEffect(() => {
    return () => setEditSettingsMode(false);
  }, []);

  const updateBackupCache = useCallback((data: IEditBackupSettingsForm) => {
    const cacheData = client.readQuery({
      query: BackupDocument,
      variables: {
        backupId: backup.id,
      },
    });

    if (!cacheData) return;

    const isNoneGracePeriod = data.disruptionNotificationGracePeriod === 'none';

    client.writeQuery({
      query: BackupDocument,
      variables: {
        backupId: backup.id,
      },
      data: {
        backup: {
          ...cacheData.backup,
          disruptionNotification: data.disruptionNotification,
          disruptionNotificationGracePeriod: isNoneGracePeriod ? null : data.disruptionNotificationGracePeriod ?? null,
          versionsLimit: data.versionsLimit,
        },
      },
    });
  }, []);

  const onSubmit = async ({
    disruptionNotification,
    disruptionNotificationGracePeriod,
    versionsLimit,
  }: IEditBackupSettingsForm) => {
    const isNoneGracePeriod = disruptionNotificationGracePeriod === 'none';

    try {
      const { data } = await updateBackup({
        variables: {
          input: {
            backupId: backup.id,
            backupParams: {
              disruptionNotification: disruptionNotification,
              disruptionNotificationGracePeriod: isNoneGracePeriod ? null : disruptionNotificationGracePeriod,
              versionsLimit: Number(versionsLimit),
            },
          },
        },
      });

      if (data?.updateBackup?.success) {
        setEditSettingsMode(false);
        updateBackupCache({
          disruptionNotification,
          disruptionNotificationGracePeriod,
          versionsLimit,
        });
        message.success('Backup settings updated successfully');
      } else {
        throw new ClientError(data?.updateBackup?.errors?.[0]);
      }
    } catch (error: unknown) {
      getApiError(error, message.error);
      throw error;
    }
  };

  const onCancel = () => setEditSettingsMode(false);

  const handleSave = () => formRef.current?.submit();

  const isUnlimitedVersion = backup.versionsLimit === null;
  const showEditSettings = !ui.isEditSettingsMode && backup.policy.update;

  return (
    <Fragment>
      <JourneyModalLayout.Main className="ck-backup__main">
        <div className="d-flex justify-content-between ck-backup__header-block mb-32">
          <TextBlock as="h2" titleColor="light-blue" title="Backup Settings" text="Review your backup configuration." />

          {showEditSettings && (
            <Button ghost type="primary" onClick={() => setEditSettingsMode(true)}>
              Edit Settings
            </Button>
          )}
        </div>

        <div className={styles.block}>
          <TextBlock
            as="h4"
            titleColor="dark-blue"
            title="Backup Schedule"
            text="Our service checks your synced repos daily, guaranteeing that the latest unique version is always backed up."
            className="mb-3"
          />
        </div>

        {ui.isEditSettingsMode ? (
          <EditBackupSettingsForm
            onSubmit={onSubmit}
            ref={formRef}
            initialValues={{
              disruptionNotification: backup.disruptionNotification,
              disruptionNotificationGracePeriod:
                backup.disruptionNotificationGracePeriod as DisruptionNotificationGracePeriodEnum,
              versionsLimit: isUnlimitedVersion ? 'unlimited' : String(backup.versionsLimit),
            }}
          />
        ) : (
          <Fragment>
            <BackupSettingInfoBlock
              heading="Version History"
              text={versionsOptionsDict[isUnlimitedVersion ? 'unlimited' : backup?.versionsLimit || '']}
            />
            <BackupSettingInfoBlock
              heading="Disrupted Connection Notifications"
              text={
                backup.disruptionNotification === DisruptionNotificationEnum.NoDistruptionNotifications
                  ? disruptedNotificationsOptionsDict[DisruptionNotificationEnum.NoDistruptionNotifications]
                  : backup.disruptionNotificationGracePeriod
                    ? gracePeriodOptionsDict[backup.disruptionNotificationGracePeriod]
                    : 'Immediate Notification'
              }
            />
            <BackupSettingInfoBlock heading="Storage Region" text={storageRegionDictionary[backup.storageRegion]} />
          </Fragment>
        )}
      </JourneyModalLayout.Main>

      {ui.isEditSettingsMode && (
        <JourneyModalFooter justify="end">
          <JourneyModalFooter.Item>
            <Button onClick={onCancel}>Cancel</Button>
          </JourneyModalFooter.Item>

          <JourneyModalFooter.Item>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </JourneyModalFooter.Item>
        </JourneyModalFooter>
      )}
    </Fragment>
  );
};

export default BackupSettingsContainer;
