import { useState, useCallback, useMemo } from 'react';
import { Button, message } from 'antd';

import { AddContactForm, IAddContactForm, IAddExistingContactForm } from 'src/components/Escrow/Contacts';
import { useCreateContactMutation, ContactTypeEnum } from 'src/graphql/schema';
import { getApiError, ClientError } from 'src/utils/errors';
import styles from 'src/components/Escrow/Contacts/EscrowContacts.module.scss';
import { useEscrow } from 'src/context/escrow';
import { updateContactsCache } from 'src/graphql/client/cache';

import type { EscrowPartyTypeEnum, IContact } from 'src/graphql/schema';

type Props = {
  role: EscrowPartyTypeEnum;
};

const EscrowAddNewContactContainer: React.FunctionComponent<Props> = ({ role }) => {
  const [isVisibleForm, setIsVisibleForm] = useState(false);

  const { escrow } = useEscrow();

  const organization = escrow[role];
  const escrowPartyId = organization?.id || '';
  const companyUsers = useMemo(
    () =>
      organization?.company?.users.filter(
        ({ email: userEmail }) => !organization?.contacts.some(({ user }) => userEmail === user?.email),
      ) || [],
    [escrow],
  );

  const [createContact] = useCreateContactMutation({
    update: (_, { data }) => {
      const newContacts = [...(organization?.contacts ?? [])];
      if (data?.createContact?.contact) newContacts.push(data.createContact.contact as IContact);
      updateContactsCache(escrow.id, role, organization, newContacts);
    },
  });

  const onSubmitNewContact = async ({ email, name, phone, role }: IAddContactForm) => {
    try {
      const { data } = await createContact({
        variables: {
          escrowPartyId,
          contactInputParams: {
            contactType: role as ContactTypeEnum,
            user: {
              email,
              name,
              phone,
            },
          },
        },
      });

      if (data?.createContact?.errors?.length) {
        throw new ClientError(data?.createContact?.errors[0]);
      } else {
        message.success('Contact added!');
      }
    } catch (e: unknown) {
      getApiError(e, message.error);
    }
  };
  const onSubmitExistingContact = async ({ id: userId, role }: IAddExistingContactForm) => {
    try {
      const { data } = await createContact({
        variables: {
          escrowPartyId,
          contactInputParams: {
            userId,
            contactType: role as ContactTypeEnum,
          },
        },
      });

      if (data?.createContact?.errors?.length) {
        throw new ClientError(data?.createContact?.errors[0]);
      } else {
        message.success('Contact added!');
      }
    } catch (e: unknown) {
      getApiError(e, message.error);
    }
  };
  const onSubmitContact = useCallback(
    async (values: IAddContactForm) => {
      const { email } = values;
      const existingContact = companyUsers.find((option) => option.email === email);

      if (existingContact) {
        await onSubmitExistingContact({
          id: existingContact.id,
          role: values.role,
        });
      } else {
        await onSubmitNewContact(values);
      }
    },
    [companyUsers],
  );
  const handleAddClick = () => setIsVisibleForm(true);
  const onCancel = () => setIsVisibleForm(false);
  const existingContacts =
    companyUsers.map(({ id, name }) => ({
      value: id || '',
      label: name || '',
    })) || [];

  return (
    <div className={styles.block}>
      {isVisibleForm ? (
        <AddContactForm
          type={role}
          onCancel={onCancel}
          onSubmitContact={onSubmitContact}
          withExistingSelect={true}
          existingContacts={existingContacts}
          onSubmitExistingContact={onSubmitExistingContact}
        />
      ) : (
        <div className={styles['action-row']}>
          <Button ghost className={styles['btn-outlined-primary']} onClick={handleAddClick}>
            + Add new contact
          </Button>
        </div>
      )}
    </div>
  );
};

export default EscrowAddNewContactContainer;
