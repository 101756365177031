import { Fragment, useState } from 'react';
import { Button, message } from 'antd';
import { capitalize } from 'lodash';
import { useLocation } from 'react-router-dom';

import { SuggestChangesActionModal } from 'src/components/SuggestChanges';
import {
  useReviewSuggestedChangeEscrowMutation,
  SuggestedChangeVerdictEnum,
  SuggestedChangeStatusEnum,
  EscrowPartyTypeEnum,
} from 'src/graphql/schema';
import { suggestChangesVar, writeNewSuggestChanges, writeRejectSuggestChanges } from 'src/graphql/client/cache';
import { useEscrow } from 'src/context/escrow';
import { getApiError, ClientError } from 'src/utils/errors';

type Props = {
  suggestedChangeId: string;
  type: 'accept' | 'reject';
};

const actionPaths = ['parties', 'deposits', 'notifications', 'agreement'] as const;

const ActionSuggestChangesContainer = ({ type, suggestedChangeId }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [reviewChanges] = useReviewSuggestedChangeEscrowMutation();
  const location = useLocation();
  const { setConfirmModalOpen } = useEscrow();

  let activePath: (typeof actionPaths)[number];
  if (location.pathname.split('/').at(-1)) {
    activePath = location.pathname.split('/').at(-1) as (typeof actionPaths)[number];
  }

  const toggleModal = (open: boolean) => () => setIsOpen(open);

  const onActionProceed = async () => {
    setIsLoading(true);

    try {
      const { data } = await reviewChanges({
        variables: {
          suggestedChangeId,
          verdict: `${type}ed` as SuggestedChangeVerdictEnum,
        },
      });

      if (data?.reviewSuggestedChangeEscrow?.success) {
        setIsOpen(false);

        if (actionPaths.includes(activePath)) {
          if (type === 'accept') {
            const reviewChangesResponse = data.reviewSuggestedChangeEscrow.suggestedChange;

            setConfirmModalOpen(true);
            writeNewSuggestChanges(suggestChangesVar)(activePath, {
              id: reviewChangesResponse.id,
              creator: reviewChangesResponse.creatorType,
              status: reviewChangesResponse.partyStatus as SuggestedChangeStatusEnum,
              payload: reviewChangesResponse.payload,
              receiver: reviewChangesResponse.receiverType as EscrowPartyTypeEnum,
            });
          } else {
            writeRejectSuggestChanges(suggestChangesVar)(activePath);
          }
        }
      } else {
        throw new ClientError(data?.reviewSuggestedChangeEscrow?.errors?.[0]);
      }
    } catch (e: unknown) {
      getApiError(e, message.error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Button type={type === 'accept' ? 'primary' : 'default'} className="btn-accept" onClick={toggleModal(true)}>
        {capitalize(type)} All Suggestions
      </Button>

      <SuggestChangesActionModal
        isOpen={isOpen}
        isLoading={isLoading}
        type={type}
        onProceed={onActionProceed}
        onCancel={toggleModal(false)}
      />
    </Fragment>
  );
};

export default ActionSuggestChangesContainer;
