import { message } from 'antd';
import { capitalize } from 'lodash';

import {
  IOrganizationForm,
  IAddContactForm,
  IContactDetail,
  EditableOrganizationDetail,
  EditableContactList,
  InfoContentBlock,
  EditableConfirmationMessage,
} from 'src/components/Escrow/Contacts';
import {
  IEscrowPartyInput,
  EscrowPartyTypeEnum,
  ContactTypeEnum,
  useUpdateContactMutation,
  useRemoveContactMutation,
  useUpdateBeneficiaryMutation,
  useCreateBeneficiaryMutation,
  useUpdateEscrowMutation,
} from 'src/graphql/schema';
import { useEscrow } from 'src/context/escrow';
import styles from 'src/components/Escrow/Contacts/EscrowContacts.module.scss';
import { getApiError, ClientError } from 'src/utils/errors';
import { updateContactsCache, updateBeneficiaryCache } from 'src/graphql/client/cache';

import BipartiteAddNewContactContainer from './BipartiteAddNewContactContainer';

type Props = {
  role: EscrowPartyTypeEnum;
};

const EscrowNewSecondaryPartyContainer: React.FunctionComponent<Props> = ({ role }) => {
  const { escrow } = useEscrow();
  const [updateContact] = useUpdateContactMutation();
  const [removeContact] = useRemoveContactMutation();
  const [updateBeneficiary] = useUpdateBeneficiaryMutation({
    update: (_, { data }) => updateBeneficiaryCache(escrow.id, data?.updateBeneficiary?.beneficiary),
  });
  const [createBeneficiary] = useCreateBeneficiaryMutation();
  const [updateEscrow] = useUpdateEscrowMutation();

  const escrowId = escrow.id;
  const organization = escrow[role];
  const escrowPartyId = escrow[role]?.id || '';
  const contacts: IContactDetail[] =
    escrow[role]?.contacts.map(({ id, contactType, email, name, phone }) => ({
      id: id || '',
      email: email || '',
      name: name || '',
      phone: phone || '',
      role: contactType || '',
    })) || [];

  const onCreateOrganization = async ({
    name,
    street,
    streetNumber,
    city,
    zip,
    state,
    country,
    website,
    registrationNumber,
  }: IOrganizationForm) => {
    try {
      const { data } = await createBeneficiary({
        variables: {
          escrowId,
          beneficiaryInputParams: {
            companyName: name,
            street,
            streetNumber,
            city,
            postalCode: zip,
            region: state,
            country,
            companyWebsite: website,
            companyRegistrationNumber: registrationNumber,
          },
        },
      });

      if (data?.createBeneficiary?.errors?.length) {
        throw new ClientError(data?.createBeneficiary?.errors[0]);
      } else {
        message.success('Beneficiary Organization Details added!');
      }
    } catch (e: unknown) {
      getApiError(e, message.error);
    }
  };

  const onUpdateOrganization = async ({
    name,
    street,
    streetNumber,
    city,
    zip,
    state,
    country,
    website,
    registrationNumber,
  }: IOrganizationForm) => {
    const companyVariables: IEscrowPartyInput = {
      companyName: name,
      street,
      streetNumber,
      city,
      postalCode: zip,
      region: state,
      country,
      companyWebsite: website,
      companyRegistrationNumber: registrationNumber,
    };
    try {
      const { data } = await updateBeneficiary({
        variables: {
          beneficiaryId: escrowPartyId,
          beneficiaryInputParams: companyVariables,
        },
      });

      if (data?.updateBeneficiary?.errors?.length) {
        throw new ClientError(data?.updateBeneficiary?.errors[0]);
      } else {
        message.success('Details updated!');
      }
    } catch (e: unknown) {
      getApiError(e, message.error);
    }
  };

  const onSubmitContact = async (id: string, { email, name, role, phone }: IAddContactForm) => {
    try {
      const { data } = await updateContact({
        variables: {
          contactId: id,
          contactInputParams: {
            name,
            email,
            phone,
            contactType: role as ContactTypeEnum,
          },
        },
      });

      if (data?.updateContact?.errors?.length) {
        throw new ClientError(data?.updateContact?.errors[0]);
      } else {
        message.success('Contact updated!');
      }
    } catch (e: unknown) {
      getApiError(e, message.error);
    }
  };
  const onRemoveContact = async (contactId: string) => {
    try {
      await removeContact({
        variables: {
          contactId,
        },
      });

      const filteredContacts = organization?.contacts.filter(({ id }) => id !== contactId);
      updateContactsCache(escrow.id, role, organization, filteredContacts);
      message.success('The contact was removed!');
    } catch (e: unknown) {
      getApiError(e, message.error);
    }
  };

  const onSubmitConfirmMessage = async ({ message: isActive }: { message: boolean }) => {
    try {
      await updateEscrow({
        variables: {
          escrowId,
          escrowInput: {
            activationNotification: isActive,
          },
        },
      });
      message.success('The Confirmation Message has changed!');
    } catch (e: unknown) {
      getApiError(e, message.error);
    }
  };

  return (
    <div className={styles.container}>
      <InfoContentBlock
        heading={`${capitalize(role)} Information`}
        text="Please add the company that will receive the material if the release clauses of the escrow agreement are triggered. Please specify at least one contact."
      />
      <div className={styles.block}>
        <EditableOrganizationDetail
          type={role}
          data={
            organization
              ? {
                  name: organization?.companyName || '',
                  address: {
                    street: organization?.street || '',
                    streetNumber: organization?.streetNumber || '',
                    city: organization?.city || '',
                    postalCode: organization?.postalCode || '',
                    region: organization?.region || '',
                    country: organization?.country || '',
                  },
                  registrationNumber: organization?.companyRegistrationNumber || '',
                  website: organization?.companyWebsite || '',
                }
              : undefined
          }
          onSubmit={organization ? onUpdateOrganization : onCreateOrganization}
        />
      </div>
      <EditableContactList
        className={styles.block}
        type={role}
        contacts={contacts}
        onSubmitContact={onSubmitContact}
        onRemoveContact={onRemoveContact}
      />
      <BipartiteAddNewContactContainer role={role} showInitForm={!contacts.length} />
      <div className={styles.block}>
        <EditableConfirmationMessage
          type={role}
          value={escrow.activationNotification}
          onSubmit={onSubmitConfirmMessage}
        />
      </div>
    </div>
  );
};

export default EscrowNewSecondaryPartyContainer;
