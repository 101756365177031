import { Fragment, useEffect } from 'react';
import { Button, message } from 'antd';

import { JourneyFooter as EscrowModalFooter } from 'src/components/Journey';
import { useEscrow } from 'src/context/escrow';
import { ModalProceed, ModalConfirmation } from 'src/components/Misc';
import { useDepositsSuggestChanges } from 'src/context/suggest-changes';
import { SuggestedChangeStatusEnum } from 'src/graphql/schema';
import RequestTerminationActionContainer from 'src/containers/Escrow/Layout/Actions/RequestTerminationActionContainer';

import ActionSuggestChangesContainer from '../../SuggestChanges/ActionSuggestChangesContainer';

const DepositsSuggestChangesActions = () => {
  const { escrow, ui, setSuggestChangesMode, setSendModalOpen, setCustomUi, setEnableSendForReviewBtn } = useEscrow();
  const { deletedIdAssets, isLoading, suggestedChanges, sendSuggestedChanges } = useDepositsSuggestChanges();

  useEffect(() => {
    if (deletedIdAssets.length) {
      return setEnableSendForReviewBtn(true);
    } else setEnableSendForReviewBtn(false);
  }, [deletedIdAssets]);

  const handleDiscardChanges = () => setSuggestChangesMode(false);

  const onProceed = async () => {
    try {
      await sendSuggestedChanges();
      setCustomUi({ isSendModalOpen: false, isConfirmModalOpen: true });
    } catch (err: unknown) {
      if (err instanceof Error) message.error(err.message || 'Something went wrong');
    }
  };

  if (isLoading || escrow.escrowTermination) return null;

  if (!ui.isSuggestChangesMode && suggestedChanges) {
    const otherPartyHasToAct =
      suggestedChanges?.creator !== escrow.currentCompanyRole &&
      suggestedChanges?.status === SuggestedChangeStatusEnum.Pending;
    if (suggestedChanges && otherPartyHasToAct)
      return (
        <EscrowModalFooter justify="space-between">
          <EscrowModalFooter.Item>
            <RequestTerminationActionContainer />
          </EscrowModalFooter.Item>

          <EscrowModalFooter.Group>
            <EscrowModalFooter.Item>
              <ActionSuggestChangesContainer type="reject" suggestedChangeId={suggestedChanges.id} />
            </EscrowModalFooter.Item>
            <EscrowModalFooter.Item>
              <ActionSuggestChangesContainer type="accept" suggestedChangeId={suggestedChanges.id} />
            </EscrowModalFooter.Item>
          </EscrowModalFooter.Group>
        </EscrowModalFooter>
      );
    return null;
  }

  if (!ui.isSuggestChangesMode) return null;

  return (
    <Fragment>
      <EscrowModalFooter justify="end">
        <EscrowModalFooter.Item>
          <Button onClick={handleDiscardChanges}>Discard Changes</Button>
        </EscrowModalFooter.Item>
        <EscrowModalFooter.Item>
          <Button type="primary" disabled={ui.isSendForReviewBtnDisabled} onClick={() => setSendModalOpen(true)}>
            Send Suggestions
          </Button>
        </EscrowModalFooter.Item>
      </EscrowModalFooter>

      <ModalProceed
        isOpen={ui.isSendModalOpen}
        title="Send change requests for review?"
        text="The counterparty will get notified, and may choose to accept or reject these changes."
        proceedText="Send"
        onProceed={onProceed}
        onCancel={() => setSendModalOpen(false)}
      />

      <ModalConfirmation
        isOpen={ui.isConfirmModalOpen}
        title="Your requests have been sent for review!"
        text="The counterparty will now review your change requests. We will notify you of the result as soon as possible!"
        onClose={() =>
          setCustomUi({
            isConfirmModalOpen: false,
            isSuggestChangesMode: false,
            isSendForReviewBtnDisabled: true,
          })
        }
      />
    </Fragment>
  );
};

export default DepositsSuggestChangesActions;
