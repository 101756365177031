/* eslint-disable jsx-a11y/aria-role */
import { Fragment, useEffect } from 'react';
import { message } from 'antd';

import { EscrowPartyTypeEnum } from 'src/graphql/schema';
import { ConfirmationMessageBlock } from 'src/components/Escrow/Contacts';
import { useContactsSuggestChanges } from 'src/context/suggest-changes';
import { shouldEnableSendForReview } from 'src/utils/validation/suggest-changes.validation';
import { getPartyOrganization, getPartySignatory } from 'src/utils/destructs/escrow.destructs';
import { useEscrow } from 'src/context/escrow';
import { ModalProceed, ModalConfirmation } from 'src/components/Misc';
import styles from 'src/components/Escrow/Contacts/EscrowContacts.module.scss';
import { getApiError } from 'src/utils/errors';

import { IPartyPreview } from '../Main/EscrowPartyPreviewContainer';

import EditableSecondaryPartyContainer from './EditableSecondaryPartyContainer';
import EditableMainPartyContainer from './EditableMainPartyContainer';
import SuggestChangesPreviewContainer from './SuggestChangesPreviewContainer';

const BipartiteSuggestChangesContainer: React.FunctionComponent = () => {
  const { escrow, ui, setEnableSendForReviewBtn, setSendModalOpen, setCustomUi } = useEscrow();
  const { view, suggestedChangesForReview, createSuggestedChanges } = useContactsSuggestChanges();

  const currentCompanyRole = escrow.currentCompanyRole as EscrowPartyTypeEnum;
  const secondPartyRole =
    currentCompanyRole === EscrowPartyTypeEnum.Beneficiary
      ? EscrowPartyTypeEnum.Depositor
      : EscrowPartyTypeEnum.Beneficiary;

  // Enable/Disable 'Send changes for Review' button
  useEffect(() => {
    const isEnabledMainParty =
      shouldEnableSendForReview(
        {
          organization: view?.[currentCompanyRole].organization,
          signatory: view?.[currentCompanyRole].signatory,
        },
        {
          organization: getPartyOrganization(escrow, currentCompanyRole),
          signatory: getPartySignatory(escrow, currentCompanyRole),
        },
      ) ||
      Boolean(suggestedChangesForReview?.[currentCompanyRole]?.editedContacts?.length) ||
      Boolean(suggestedChangesForReview?.[currentCompanyRole]?.deletedContacts?.length) ||
      Boolean(suggestedChangesForReview?.[currentCompanyRole]?.newContacts?.length);

    const isEnabledSecondaryParty =
      shouldEnableSendForReview(
        {
          organization: view?.[secondPartyRole].organization,
        },
        { organization: getPartyOrganization(escrow, secondPartyRole) },
      ) ||
      Boolean(suggestedChangesForReview?.[secondPartyRole]?.editedContacts?.length) ||
      Boolean(suggestedChangesForReview?.[secondPartyRole]?.deletedContacts?.length) ||
      Boolean(suggestedChangesForReview?.[secondPartyRole]?.newContacts?.length);

    if (isEnabledMainParty || isEnabledSecondaryParty) setEnableSendForReviewBtn(true);
    else setEnableSendForReviewBtn(false);
  }, [view, suggestedChangesForReview]);

  const onSubmitChanges = async () => {
    try {
      await createSuggestedChanges([currentCompanyRole, secondPartyRole]);
      setCustomUi({ isSendModalOpen: false, isConfirmModalOpen: true });
    } catch (e: unknown) {
      getApiError(e, message.error);
    }
  };

  return (
    <Fragment>
      {ui.isSuggestChangesMode ? (
        <Fragment>
          <EditableMainPartyContainer role={currentCompanyRole} />
          <EditableSecondaryPartyContainer role={secondPartyRole} />
        </Fragment>
      ) : (
        <Fragment>
          <SuggestChangesPreviewContainer
            parties={view as { [key in EscrowPartyTypeEnum]: IPartyPreview }}
            currentCompanyRole={currentCompanyRole}
          />
          <div className={styles['container']}>
            <ConfirmationMessageBlock
              className={styles.block}
              role={EscrowPartyTypeEnum.Beneficiary}
              value={escrow.activationNotification}
            />
          </div>
        </Fragment>
      )}

      <ModalProceed
        isOpen={ui.isSendModalOpen}
        title="Send change requests for review?"
        text="The counterparty will get notified, and may choose to accept or reject these changes."
        proceedText="Send"
        onProceed={onSubmitChanges}
        onCancel={() => setSendModalOpen(false)}
      />

      <ModalConfirmation
        isOpen={ui.isConfirmModalOpen}
        title="Your requests have been sent for review!"
        text="The counterparty will now review your change requests. We will notify you of the result as soon as possible!"
        onClose={() =>
          setCustomUi({
            isConfirmModalOpen: false,
            isSuggestChangesMode: false,
            isSendForReviewBtnDisabled: true,
          })
        }
      />
    </Fragment>
  );
};

export default BipartiteSuggestChangesContainer;
